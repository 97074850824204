import React from "react"
import { Link } from "gatsby"
import { NavWrapper } from "../elements"

export const Nav = () => {
  return (
    <NavWrapper>
      <div className="nav">
        <ul>
          <li>
            <Link to="/profile/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                viewBox="0 0 512 512"
              >
                <defs />
                <path d="M435.95 287.52a58.86 58.86 0 100-117.72 58.87 58.87 0 000 117.72zM511.33 344.25c-2.63-15.76-15.65-37.82-25.52-47.68-1.3-1.3-7.1-1.6-8.67-.63a78.38 78.38 0 01-41.19 11.71 78.37 78.37 0 01-41.17-11.71c-1.58-.97-7.38-.67-8.68.63a77.88 77.88 0 00-8.7 10.69c7.94 14.94 14.23 30.8 16.5 44.47 2.27 13.7 1.54 26.67-2.13 38.5 13.03 4.71 28.67 6.78 44.18 6.78 40.45 0 81.85-14.02 75.38-52.76zM254.49 262.7c52.68 0 95.4-42.73 95.4-95.41 0-52.67-42.72-95.39-95.4-95.39-52.68 0-95.38 42.72-95.38 95.39 0 52.69 42.7 95.4 95.38 95.4zM335.27 277.3c-2.07-2.06-11.47-2.59-14.03-1a126.96 126.96 0 01-133.5 0c-2.55-1.59-11.95-1.06-14.02 1-16.02 16-37.13 51.78-41.38 77.3-10.48 62.82 56.63 85.5 122.15 85.5 65.53 0 132.64-22.68 122.16-85.5-4.25-25.52-25.36-61.3-41.38-77.3zM76.05 287.52a58.86 58.86 0 100-117.72 58.86 58.86 0 100 117.72zM115.1 351.73c2.4-14.35 9.22-31.25 17.76-46.88a72.28 72.28 0 00-6.96-8.28c-1.3-1.3-7.1-1.6-8.67-.63a78.38 78.38 0 01-41.18 11.71c-15.1 0-29.2-4.34-41.18-11.71-1.57-.97-7.39-.67-8.68.63-9.9 9.86-22.89 31.92-25.52 47.68C-5.8 383 35.6 397.01 76.05 397.01c14.44 0 29.02-1.77 41.46-5.84-3.92-12.06-4.75-25.34-2.42-39.44z" />
              </svg>
              <br />
              どんなお店？
            </Link>
          </li>
          <li>
            <Link to="/marusue-b/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.0"
                viewBox="0 0 640 480"
                style={{ width: "40px" }}
              >
                <defs />
                <path d="M191 40v4h11v30h23v11h218V74h22V44h12v-8H191v4zM150 229.9v157.9l4.8.5c3.8.3 10.1-1.2 33.7-8 16-4.6 30.5-8.9 32.3-9.4l3.2-1.1V90.4l-32.3-9.2c-20.1-5.7-34-9.2-37-9.2H150v157.9zm10.7 79.4c-.1 42.5-.2 7.4-.2-77.8 0-85.3.1-120 .2-77.2.2 42.8.2 112.6 0 155zM475 81.3l-31.5 9.2-.3 139.7c-.1 76.9 0 139.8.3 139.8s14.8 4.2 32.3 9.4c27.4 8.1 32.4 9.3 37 8.9l5.2-.6V72l-5.7.1c-4.1.1-15 2.8-37.3 9.2zm33 150.2c0 102.3-.3 154.5-1 154.5-1.1 0-1.5-307.2-.3-308.3.3-.4.8-.7 1-.7.2 0 .3 69.5.3 154.5zM230 229.5V369h208V90H230v139.5zm63.8-84.9l2.2 1.5v51.8c0 38.9-.3 52-1.2 52.9-1.7 1.7-54.9 1.7-56.6 0-.9-.9-1.2-14-1.2-52.8 0-28.3.4-52 .8-52.6 1.3-2.1 3.7-2.2 28.7-2.3 21.1-.1 25.4.1 27.3 1.5zm67 0l2.2 1.5v51c0 33.3-.4 51.7-1 53-1 1.8-2.4 1.9-28.5 1.9H306l-1.5-2.4c-1.4-2-1.5-8.6-1.3-53 .3-48.9.4-50.8 2.2-52.2 3-2.2 52.2-2.1 55.4.2zm67.2.4c1.9 1.9 2 3.3 2 52.7 0 45.7-.2 50.8-1.7 52.5-1.5 1.7-3.7 1.8-28.1 1.8-23.3 0-26.8-.2-28.4-1.7-1.7-1.5-1.8-4.5-1.8-52.5 0-49.5.1-50.9 2-52.8 1.9-1.9 3.3-2 28-2s26.1.1 28 2zm3.5 164v4.5l-25.7.3-25.8.2v43l25.8.2 25.7.3v5l-96.7.3-96.8.2v-6h44v-43h-44v-10l96.8.2 96.7.3v4.5z" />
                <path d="M287 335.5V357h87v-43h-87v21.5zM223 381.5v6.5h-19v42h-8v20h276v-20h-8v-42h-19v-13H223v6.5zm45.8 19.7c1.7 1.7 1.5 10.3-.2 11.7-.9.8-8.3 1.1-24.7.9l-23.4-.3-.3-5.4c-.2-2.9-.1-6 .2-6.7.7-1.9 46.5-2.1 48.4-.2zm87.6-.6c2.3.9 2.3 11.9 0 12.8-2 .8-44.8.8-46.8 0s-2.3-11.9-.3-12.7c1.8-.8 45.2-.8 47.1-.1zm89.4.6c.7.7 1.2 3.3 1.2 5.8s-.5 5.1-1.2 5.8c-1.7 1.7-46.9 1.7-48.6 0-1.6-1.6-1.6-10 0-11.6 1.7-1.7 46.9-1.7 48.6 0z" />
              </svg>
              <br />
              お仏壇
            </Link>
          </li>
          <li>
            <Link to="/float/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                viewBox="0 0 512 512"
              >
                <defs />
                <path d="M28.72 447.71v24.08c0 4.26 3.5 7.75 7.76 7.75h99.85v-39.58H36.48a7.75 7.75 0 00-7.76 7.75zM475.52 439.96h-99.85v39.58h99.85c4.26 0 7.76-3.5 7.76-7.75V447.7c0-4.3-3.5-7.75-7.76-7.75z" />
                <path d="M476.19 112.59a62.69 62.69 0 00-32.02-8.8 65.3 65.3 0 00-29.82 7.36 64 64 0 00-21.68 18.54c-.57.74-1.2 1.44-1.75 2.2l-16.02-21.84a72.47 72.47 0 00-58.01-29.2h-35.24V61.27H296.02V11.6H215.98V61.27h14.36v19.58h-35.23a72.62 72.62 0 00-58.01 29.2l-11.94 16.34-4.08 5.5a65 65 0 00-23.38-20.74 65.61 65.61 0 00-29.87-7.37 62.68 62.68 0 00-32.02 8.81C17.19 123.65-1.09 149.69.05 183c1.05 28 11.64 49.21 34.28 68.88 22.2 19.3 56.29 28.05 98.94 31.98h.04l11.3 16.8h6.55v15.87h-33.4l-12.9-.06.03.06h-.17l15.12 36.12h31.32v66.39h-7.84V500.42h225.36v-81.38h-7.84v-66.4h31.32l10.94-26.12 4.18-9.83h-.06l.06-.16h-46.44v-15.87h6.55l11.3-16.8h.04c42.65-3.93 76.73-12.68 98.94-31.98C500.32 232.21 510.9 211 511.95 183c1.15-33.31-17.14-59.35-35.76-70.41zM350.01 479.54h-31.78v-39.58H350v39.58zm-70.75-145.66V352.64h-46.52v-18.76h46.52zm0 38.3v46.86h-46.53v-46.86h46.53zm-85.49 107.36H162v-39.58h31.78v39.58zm-77.05-317.6l.1-.14.03.24 31.78-43.42a57.99 57.99 0 0146.48-23.4H316.9a58.08 58.08 0 0146.43 23.3l17.62 24.09L395 162.03l.02-.19.14.2c.34-14.75 10.96-30.45 25.8-38.15a50.93 50.93 0 0123.22-5.75c8.7 0 17.23 2.35 24.7 6.8 14.98 8.9 29.68 30.1 28.72 57.54-.91 23.98-9.67 41.5-29.3 58.54-33.7 29.3-102.3 31.59-188.45 31.59h-47.68c-86.11 0-154.7-2.3-188.46-31.59-19.62-17.04-28.38-34.56-29.29-58.5-.96-27.43 13.74-48.68 28.72-57.58a48.22 48.22 0 0124.7-6.8c7.95 0 15.99 2.01 23.22 5.75 12.6 6.54 22.1 18.86 24.9 31.46.45 2.2.76 4.4.77 6.6zM298.79 412.1v-39.92h12.6v-19.54h-12.6v-18.76h23.5v-19.53H189.7v19.53h23.5V352.64h-12.58v19.54h12.58v46.86H179.83V300.66h152.33v118.38H298.79v-6.94zM281.65 46.9h-51.3V25.95h51.3V46.9z" />
                <path d="M63.28 178.85c.35.62.7 1.23 1.1 1.8.8 1.19 1.9 2.14 3.02 3.06 1.01.84 2.13 1.5 3.33 2.13l.73.38c1.24.57 2.48 1 3.8 1.33.2.06.41.09.62.13 1.46.32 2.89.5 4.35.5 1.32 0 2.65.03 3.88-.25 7.13-1.53 18.86-7.18 18.38-25.56-.24-9.96-8.1-20.53-18.05-25.7-10.96-5.7-23.64-5.51-33.99.62-11.67 6.94-22.4 23.89-21.68 44.66.72 18.9 6.85 33.03 24.37 48.25C87.27 259.78 171.8 258.25 256 258.25c84.2 0 168.78 1.53 202.86-28.05 17.52-15.22 23.65-29.34 24.37-48.25.72-20.77-10-37.72-21.69-44.66-10.33-6.13-23.02-6.32-33.98-.62-9.96 5.17-17.8 15.74-18.05 25.7-.48 18.38 11.25 24.03 18.38 25.56 1.23.28 2.56.25 3.87.25 1.47 0 2.9-.18 4.36-.5.21-.04.4-.07.61-.13 1.34-.32 2.57-.76 3.81-1.33l.73-.38c2.5-1.3 4.81-2.93 6.35-5.18.31-.46.6-.96.88-1.45l.31-.35c.08 1.06.09 2.19 0 3.37-.48 6.18-4.6 12.7-10.1 17.1-6.6 5.21-25.51 9.71-44.76-14.51l-42.17-57.73a43.66 43.66 0 00-34.9-17.52H195.12a43.66 43.66 0 00-34.9 17.52l-42.17 57.73c-19.24 24.22-38.15 19.72-44.75 14.5-5.5-4.4-9.62-10.91-10.1-17.09a22.11 22.11 0 010-2.87c.03-.17.05-.35.1-.51zm237.29 31.34a19.51 19.51 0 01-16.95 9.91 19.6 19.6 0 01-12.54-4.6 19.66 19.66 0 01-6.8-12.82 19.59 19.59 0 0111.1-19.63 19.9 19.9 0 018.3-1.87c2.1 0 4.15.39 6.16 1.05l5.36 1.82 1.87-5.4a45.57 45.57 0 002.4-14.99c0-.95-.05-1.86-.15-2.82-.06-1-.3-1.99-.44-2.99-.14-1.2-.29-2.48-.43-3.83a53.73 53.73 0 012.12 56.17zm-94.65-50.47a53.74 53.74 0 0147.93-29.52 19.58 19.58 0 0119.1 23.12 19.47 19.47 0 01-30.4 12.21 19.19 19.19 0 01-7.8-12.2l-1.1-5.6-5.6 1.05a45.23 45.23 0 00-16.61 6.8c-1.06.7-2 1.55-3 2.34l-2.52 1.8zm8.15 24.72a19.4 19.4 0 0117.85-1 19.42 19.42 0 0111.5 19.43 19.52 19.52 0 01-6.57 12.97l-4.18 3.67-.12.11 3.78 4.31a45.7 45.7 0 0011.77 9.57c.86.43 1.68.91 2.54 1.3 1.14.56 2.4.95 3.61 1.42.96.41 1.9.84 2.87 1.27a54.03 54.03 0 01-49.94-26.34 19.63 19.63 0 016.89-26.71z" />
              </svg>
              <br />
              山車・御神輿
            </Link>
          </li>
          <li>
            <Link to={`../sword-gallery/`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                viewBox="0 0 512 512"
              >
                <defs />
                <path d="M314.48 137.5a9.22 9.22 0 00-13.05 0l-23.84 23.85a9.22 9.22 0 000 13.05l74.4 74.4a9.22 9.22 0 0013.05 0l23.84-23.85a9.22 9.22 0 000-13.04l-74.4-74.4zM388.14 174.82L512 42.9l-28.5-28.51-128.06 120.22-3.76 3.53-.11.11zM278.12 211.89l-.11-.12L11.7 478.08c-52.5 52.5 86.22-13.02 127.07-53.86l175.83-175.84-36.41-36.41-.08-.08z" />
              </svg>
              <br />
              鞘塗
            </Link>
          </li>
          {/* <li>
            <Link to={`../others/`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                viewBox="0 0 512 512"
              >
                <defs />
                <path d="M82.05 149.32a175.82 175.82 0 0165.03-26.48l3.99-.75.76 3.97a105.61 105.61 0 0042.74 66.51 105.37 105.37 0 0061.48 19.62c22.2 0 43.45-6.78 61.47-19.62a105.6 105.6 0 0044.61-86.48C362.13 48.34 315.15.74 257.45.01l-1.4-.01C114.89 0 .05 114.84.05 256c0 11.55.74 22.97 2.24 34.23a178.8 178.8 0 0179.76-140.91z" />
                <path d="M381.74 32.7a260.7 260.7 0 00-30.94-14.87 178.87 178.87 0 0184.05 149.76c.2 20.05-2.94 39.77-9.34 58.6l-1.3 3.83-3.85-1.29a105.14 105.14 0 00-33.69-5.52c-15.52 0-31.16 3.49-45.23 10.1a105.27 105.27 0 00-47.28 43.9 105.3 105.3 0 00-13.1 63.19A105.56 105.56 0 00318 410.28a106.15 106.15 0 0068.5 25.15 106.5 106.5 0 0091.8-52.8l.7-1.2c69.32-122.97 25.7-279.4-97.26-348.73z" />
                <path d="M251.12 459.92a176.24 176.24 0 01-55.94-42.63l-2.67-3.05 3.04-2.68a105.82 105.82 0 0035.68-70.73 105.53 105.53 0 00-14.34-63.04 105.54 105.54 0 00-48.21-43.06 107.56 107.56 0 00-43.44-9.24c-12.22 0-24.24 2.09-35.73 6.22a107.96 107.96 0 00-18.2 8.55c-49.88 29.4-67.06 94.16-38.33 144.35l.7 1.2C79.58 463.64 164.3 512 254.82 512a256.5 256.5 0 00130.13-35.62 259.06 259.06 0 0027.97-19.02 178.27 178.27 0 01-83.48 20.6c-26.96 0-54.05-6.25-78.32-18.04z" />
              </svg>
              <br />
              伝統工芸
            </Link>
          </li> */}
          <li>
            <Link to="../gallery/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                viewBox="0 0 512 512"
              >
                <defs />
                <path d="M82.05 149.32a175.82 175.82 0 0165.03-26.48l3.99-.75.76 3.97a105.61 105.61 0 0042.74 66.51 105.37 105.37 0 0061.48 19.62c22.2 0 43.45-6.78 61.47-19.62a105.6 105.6 0 0044.61-86.48C362.13 48.34 315.15.74 257.45.01l-1.4-.01C114.89 0 .05 114.84.05 256c0 11.55.74 22.97 2.24 34.23a178.8 178.8 0 0179.76-140.91z" />
                <path d="M381.74 32.7a260.7 260.7 0 00-30.94-14.87 178.87 178.87 0 0184.05 149.76c.2 20.05-2.94 39.77-9.34 58.6l-1.3 3.83-3.85-1.29a105.14 105.14 0 00-33.69-5.52c-15.52 0-31.16 3.49-45.23 10.1a105.27 105.27 0 00-47.28 43.9 105.3 105.3 0 00-13.1 63.19A105.56 105.56 0 00318 410.28a106.15 106.15 0 0068.5 25.15 106.5 106.5 0 0091.8-52.8l.7-1.2c69.32-122.97 25.7-279.4-97.26-348.73z" />
                <path d="M251.12 459.92a176.24 176.24 0 01-55.94-42.63l-2.67-3.05 3.04-2.68a105.82 105.82 0 0035.68-70.73 105.53 105.53 0 00-14.34-63.04 105.54 105.54 0 00-48.21-43.06 107.56 107.56 0 00-43.44-9.24c-12.22 0-24.24 2.09-35.73 6.22a107.96 107.96 0 00-18.2 8.55c-49.88 29.4-67.06 94.16-38.33 144.35l.7 1.2C79.58 463.64 164.3 512 254.82 512a256.5 256.5 0 00130.13-35.62 259.06 259.06 0 0027.97-19.02 178.27 178.27 0 01-83.48 20.6c-26.96 0-54.05-6.25-78.32-18.04z" />
              </svg>
              <br />
              制作実績
            </Link>
          </li>

          <li>
            <Link to="/contact/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                viewBox="0 0 512 512"
              >
                <defs />
                <path d="M123.67 288.7a16.54 16.54 0 0016.03-3.07c6.5-5.52 9.58-14.87 4.83-44.4-4.2-26.18-10.71-39.89-21.77-45.83a23.91 23.91 0 00-7.64-2.6c.09-48.8 16.8-93.89 45.99-123.88C186.04 43.3 218.85 29.75 256 29.75s69.96 13.54 94.89 39.17c29.18 30 45.9 75.08 45.99 123.88a23.91 23.91 0 00-7.64 2.6c-11.06 5.94-17.57 19.65-21.77 45.83-1.8 11.18-2.54 19.83-2.25 26.4-8.6 4.6-23.99 10.3-49.57 12.52a22.18 22.18 0 00-19-10.85h-14.76a22.28 22.28 0 00-22.26 22.26 22.28 22.28 0 0022.26 22.26h14.76c8.68 0 16.49-5 20.14-12.8 23.27-1.94 42.48-6.72 57.11-14.24a16.23 16.23 0 0014.43 1.91c11.77-3.92 36.6-30.75 38.2-88.68 1.64-59.33-18.15-114.66-54.31-151.83C341.56 16.66 301.37 0 256 0c-45.38 0-85.56 16.66-116.22 48.17-36.16 37.18-55.95 92.51-54.31 151.84 1.6 57.93 26.43 84.76 38.2 88.68z" />
                <path d="M452.73 457.03a64.47 64.47 0 00-36.52-47l-80.04-32.7a36.16 36.16 0 01-21.01-32.78v-6.45l-2.11.73a49.95 49.95 0 01-16.4 2.8h-14.76a50.13 50.13 0 01-50.08-50.07 50.13 50.13 0 0150.08-50.07h14.76c10.3 0 20.2 3.13 28.63 9.05l.53.37.64-.1c17.62-2.9 25.74-7.52 28.82-9.76l5.26-7.7c5.16-16.56 7.77-32.11 7.77-46.22 0-74.7-47.23-128.9-112.3-128.9s-112.3 54.2-112.3 128.9c0 61.81 50.2 129.51 50.47 129.75a48.68 48.68 0 012.67 15.89v11.79c0 14.04-8.25 26.9-20.95 32.74l-80.17 32.77a64.44 64.44 0 00-36.47 47.12L55 512h402l-4.27-54.97z" />
              </svg>
              <br />
              お問い合わせ
            </Link>
          </li>
        </ul>
      </div>
    </NavWrapper>
  )
}
