import React from "react"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import { MDXProvider } from "@mdx-js/react"
import { MDXEmbedProvider } from "mdx-embed"
import Theme from "./src/themes/theme"
import { Table } from "./src/components"

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body, html {
    font-family: ${props => props.theme.fonts.main};
    height: 100%;
    background-color: ${props => props.theme.colors.light1};
  }

  a {
	color: inherit;
  text-decoration: none;
  }
`

const components = {
  table: Table,
}

export const wrapRootElement = ({ element }) => (
  <MDXProvider components={components}>
    <ThemeProvider theme={Theme}>
      <GlobalStyle />
      <MDXEmbedProvider>{element}</MDXEmbedProvider>
    </ThemeProvider>
  </MDXProvider>
)
