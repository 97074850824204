import styled from "styled-components"

export const ImgViewerWrapper = styled.div`
 .imageList {
  width: 620px;
  display: flex;
}
.imageList__view {
  flex: 0 0 440px;
  height: 440px;
}

.imageList__view img {
  display: block;
  width: 100%;
}

.imageList__thumbs {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  height: 440px;
  overflow: scroll;
}

.imageList__thumbnail {
  margin-left: 10px;
  flex: 0 1 calc(50% - 10px);
  overflow: hidden;
}

.imageList__thumbnail img{
  object-fit: cover;
  height: 80px;
  width: 80px;
}

.imageList__thumbnail:nth-child(n + 3) {
  margin-top: 10px;
}


.selected {
  position: relative;
}

.selected::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #c37e00;
  opacity: .7;
}
  `