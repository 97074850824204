import styled from "styled-components"

export const MainWrapper = styled.main`
  grid-column: 3 / -3;
  background-color: ${props => props.theme.colors.light2};
  padding: ${props =>
    `${props.theme.spacings.large} ${props.theme.spacings.xLarge}`};
  box-shadow: ${props => props.theme.shadows.shadows3};
  z-index: 10;
  margin-bottom: ${props => props.theme.spacings.medium};
  position: relative;

  @media ${props => props.theme.breakpoints.mobile} {
    grid-column: 1 / -1;
    padding: ${props =>
      `${props.theme.spacings.xSmall} ${props.theme.spacings.small}`};
  }
`
