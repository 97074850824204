import styled from "styled-components"

export const FooterWrapper = styled.footer`
  grid-column: 2 / -2;
  min-height: 11.25rem;
  padding: 3rem 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  position: relative;

  @media ${props => props.theme.breakpoints.mobile} {
    grid-column: 1 / -1;
  }
`

export const FooterSocialWrapper = styled.div`
  z-index: 10;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;

  & p {
    text-align: center;
    flex: 0 0 100%;
  }
`

export const FooterSocialIcons = styled.div`
  flex: 0 0 100%;
  margin-bottom: 2rem;

  & img,
  svg {
    height: 30px;
    padding: 0 0.75rem;
    transition: filter 0.3s ease;
  }

  & svg:hover,
  svg:focus {
    filter: brightness(0.5);
  }
`

export const FooterImgWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  .overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
`
