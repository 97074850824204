import React from "react"
import { ContactWrapper } from "../elements"
import { PhoneNumber } from "../components"

export const Contact = () => {
  return (
    <ContactWrapper>
      <div className="center">
        <PhoneNumber text="お電話でお問い合わせ" width="280px"/>
      </div>
      <div className="title center">
        お問い合わせフォーム
        <span>info@marusue.jp</span>
      </div>
      <form
        name="contact"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        action="/success"
        netlify
      >
        <input type="hidden" name="form-name" value="contact" />
        <input type="hidden" name="bot-field" />
        <label htmlFor="name">
          <p>お名前</p>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            placeholder="Name"
            maxLength="30"
            minLength="2"
            required
            autoComplete="name"
          />
        </label>
        <label htmlFor="email">
          <p>メールアドレス</p>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            placeholder="Email"
            pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
            required
            autoComplete="email"
          />
        </label>
        <label htmlFor="content">
          <p>お問合せ内容</p>
          <textarea
            className="form-control"
            id="contact"
            name="content"
            placeholder="・○○のお仏壇について知りたい&#13;&#10;・○○を漆で塗ってみたい"
            rows="8"
            required
            style={{ width: "100%" }}
          ></textarea>
        </label>
        <div className="center" style={{marginTop: "0.5rem"}}>
          <button type="submit">送信</button>
        </div>
      </form>
    </ContactWrapper>
  )
}
