import styled from "styled-components"

export const ContactWrapper = styled.div`
  ::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }

  form {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
  }

  .title {
    margin: 4rem 0 1rem 0;
    font-weight: 700;
  }

  .center {
    text-align: center;
  }

  span {
    color: ${props => props.theme.colors.dark3};
    margin-left: 1rem;
    font-weight: 400;
  }

  input {
    width: 100%;
  }

  input,
  textarea {
    font-size: 1rem;
    line-height: 2rem;
    padding: 0.2rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px;
    margin-bottom: 1rem;
  }

  button {
    outline: none;
    height: 40px;
    text-align: center;
    width: 200px;
    border-radius: 40px;
    background: #fff;
    border: 2px solid #1ecd97;
    color: #1ecd97;
    letter-spacing: 1px;
    text-shadow: 0;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }

  button:hover {
    color: white;
    background: #1ecd97;
  }
  button:active {
    letter-spacing: 2px;
  }

  input:focus,textarea:focus {
    outline: 0;
    border-color: ${props => props.theme.colors.main1};
  }

  @media ${props => props.theme.breakpoints.mobile} {
    input {
      width: 80%;
    }
  }
`
