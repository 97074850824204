import styled from "styled-components"

export const NavWrapper = styled.nav`
  background-color: ${props => props.theme.colors.opacityWhite};
  grid-column: 2 / -2;
  display: flex;
  align-items: center;
  height: 80px;
  position: sticky; /*固定する*/
  position: -webkit-sticky; /*Safari用ベンダープレフィックス*/
  top: 0; /*ブラウザの上からの距離はゼロ*/
  z-index: 100;

  @media ${props => props.theme.breakpoints.mobile} {
    grid-column: 1 / -1;
    flex-direction: column;
    height: auto;
  }

  & img {
    height: 2rem;
    margin-left: 2rem;

    @media ${props => props.theme.breakpoints.mobile} {
      height: 2rem;
      margin: 0.5rem 0 0 0;
    }
  }

  & .nav {
    margin: auto;

    @media ${props => props.theme.breakpoints.mobile} {
      margin: 0;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      background-color: ${props => props.theme.colors.opacityDark};
      color: white;
      height: 80px;
    }
  }

  & ul {
    display: flex;
    @media ${props => props.theme.breakpoints.mobile} {
      display: inline-table;
      vertical-align: top;
      max-width: 100%;
      height: 100%;
      text-align: center;
      table-layout: fixed;
      width: 100%;
      font-size: 12px;
    }
  }

  & li {
    list-style: none;
    /* flex-wrap: wrap; */
    padding: 0 2rem;
    white-space: nowrap;
    border-right: 1px solid ${props => props.theme.colors.dark2};
    @media ${props => props.theme.breakpoints.mobile} {
      padding: 0;
      display: table-cell;
      width: 80px;
    }
  }

  & li:first-child {
    border-left: 1px solid ${props => props.theme.colors.dark2};
  }

  & br {
    display: none;
    @media ${props => props.theme.breakpoints.mobile} {
      display: block;
    }
  }

  & li a {
    @media ${props => props.theme.breakpoints.mobile} {
      display: block;
      padding: 12px 0;
    }
  }

  & svg {
    display: none;
    @media ${props => props.theme.breakpoints.mobile} {
      display: inline;
      fill: white;
      width: 30px;
      height: auto;
    }
  }

  & ::-webkit-scrollbar {
    display: none;
  }

  & a:hover,
  a:focus {
    @media ${props => props.theme.breakpoints.mobile} {
      filter: brightness(0.5);
      color: white;
      text-decoration: none;
    }
  }

  & li:hover,
  li:focus {
    color: ${props => props.theme.colors.main1};
    /* transform: scale(1.2, 1.2); */
    transition: 1s all;
    @media ${props => props.theme.breakpoints.mobile} {
      color: white;
    }
  }
`
