import styled from "styled-components"

export const CutinWrapper = styled.div`
  grid-column: 3 / -3;
  position: relative;

  @media ${props => props.theme.breakpoints.mobile} {
    grid-column: 2 / -2;
  }

  & :before {
    content: "";
    display: block;
    position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: white;
    z-index: 99;
    -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    clip-path: polygon(150% 50%, 0% 50%, 0% 200%);
    background-color: ${props => props.theme.colors.light2};
  }
`
