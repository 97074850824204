import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import {
  HeroWrapper,
  HeroInnerWrapper,
  HeroScrollWrapper,
  HeroTextWrapper,
} from "../elements"

export const HeroImage = () => {
  const data = useStaticQuery(graphql`
    query {
      minisanbo: file(relativePath: { eq: "minisanbo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 30) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      kumitate: file(relativePath: { eq: "kumitate.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 30) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      nakaroku: file(relativePath: { eq: "nakaroku.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 30) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      shokuninmachi: file(relativePath: { eq: "shokuninmachi.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 30) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      goldroom: file(relativePath: { eq: "goldroom.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 40) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      saya2: file(relativePath: { eq: "saya2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 40) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <HeroWrapper>
        <HeroScrollWrapper>
          <div>
            <span>↑</span>
            <div>
              <span>
                mouse <br /> scrolling
              </span>
            </div>
          </div>
          <div>
            <span>↓</span>
          </div>
        </HeroScrollWrapper>
        <HeroInnerWrapper>
          <Img
            alt="お仏壇"
            fluid={data.minisanbo.childImageSharp.fluid}
            style={{
              display: "flex",
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            loading="eager"
            durationFadeIn={100}
          />
          <div className="hero_title">
            <p style={{ fontWeight: "bold", marginBottom: "0.5rem" }}>「工房直送」</p>
            <p>
            社長自ら職人を務めるマルスエが目指すのは、<br />
            「お客様が本当に求めるものを作りたい。」<br />
            そのために、正しい情報が必要です。<br />
            そんな想いでウェブサイトを立ち上げました。<br />
            当ウェブサイトでは、マルスエにできること、<br />
            デザイン、お役立ち情報を体験することが出来ます。
            </p>
          </div>
        </HeroInnerWrapper>
      </HeroWrapper>
      <HeroWrapper>
        <HeroInnerWrapper>
          <Img
            alt="お仏壇組み立て工程"
            fluid={data.kumitate.childImageSharp.fluid}
            style={{
              display: "flex",
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            loading="eager"
            durationFadeIn={100}
          />
          <HeroTextWrapper>Altar</HeroTextWrapper>
        </HeroInnerWrapper>
      </HeroWrapper>
      <HeroWrapper>
        <HeroInnerWrapper>
          <Img
            alt="山車摺漆工程"
            fluid={data.nakaroku.childImageSharp.fluid}
            style={{
              display: "flex",
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            loading="eager"
            durationFadeIn={100}
          />
          <HeroTextWrapper>Float</HeroTextWrapper>
        </HeroInnerWrapper>
      </HeroWrapper>
      <HeroWrapper>
        <HeroInnerWrapper>
          <Img
            alt="堅地呂色仕上げ（国産漆）"
            fluid={data.shokuninmachi.childImageSharp.fluid}
            style={{
              display: "flex",
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            loading="eager"
            durationFadeIn={100}
          />
          <HeroTextWrapper>Urushi</HeroTextWrapper>
        </HeroInnerWrapper>
      </HeroWrapper>
      <HeroWrapper>
        <HeroInnerWrapper>
          <Img
            alt="マンション内装金箔施工"
            fluid={data.goldroom.childImageSharp.fluid}
            style={{
              display: "flex",
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            loading="eager"
            durationFadeIn={100}
          />
          <HeroTextWrapper>Gold leaf</HeroTextWrapper>
        </HeroInnerWrapper>
      </HeroWrapper>
      <HeroWrapper>
        <HeroInnerWrapper>
          <Img
            alt="鞘塗り"
            fluid={data.saya2.childImageSharp.fluid}
            style={{
              display: "flex",
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            loading="eager"
            durationFadeIn={100}
          />
          <HeroTextWrapper>Japanese Sword</HeroTextWrapper>
        </HeroInnerWrapper>
      </HeroWrapper>
    </>
  )
}
