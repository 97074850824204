export default {
  fonts: {
    main: "Hiragino sans, sans-serif",
    // code: "Roboto Mono, monospace",
  },
  colors: {
    main1: "hsl(207,70%,59%)",
    main2: "hsl(207,70%,94%)",
    dark1: "hsl(227,2%,12%)",
    dark2: "hsl(227,2%,26%)",
    dark3: "hsl(227,2%,64%)",
    opacityDark: "rgba(0, 0, 0, 0.8)",
    light1: "hsl(0,0%,97%)",
    light2: "hsl(0,0%,99%)",
    opacityWhite: "rgba(252, 252, 252, 0.7)",
  },
  breakpoints: {
    mobile: "only screen and (max-width: 767px)",
    tablet: "only screen and (max-width: 991px)",
  },
  spacings: {
    xxSmall: ".25rem",
    xSmall: ".5rem",
    small: "1rem",
    medium: "2rem",
    large: "3rem",
    xLarge: "4rem",
    xxLarge: "6rem",
  },
  animations: {
    button: "box-shadow 0.3s ease",
    link: "color 0.2s ease",
  },
  shadows: {
    shadow1: "rgba(30, 30, 31, 0.05) 0px 5px 20px",
    shadow2: "rgba(0, 0, 0, 0.06) 0px 2px 4px 0px",
    shadow3: "rgba(0, 0, 0, 0.08) 11px 13px 27px 6px"
  },
}
