import styled from "styled-components"

export const P = styled.p`
  margin: ${props => (props.margin ? props.margin : 0)};
  font-family: ${props => {
    switch (props.font) {
      case "cover":
        return '"Courgette", serif'
      default:
        return props.theme.fonts.main
    }
  }};
  font-size: ${props => {
    switch (props.size) {
      case "medium":
        return "1.125rem"
      case "small":
        return "1rem"
      case "xSmall":
        return "0.875rem"
      default:
        return "1.125rem"
    }
  }};
  line-height: ${props => {
    switch (props.size) {
      case "medium":
        return "1.4375rem"
      case "small":
        return "1.375rem"
      case "xSmall":
        return "1.125rem"
      default:
        return "1.4375rem"
    }
  }};
  text-decoration: ${props =>
    props.textDecoration ? props.textDecoration : "none"};
  font-weight: ${props => {
    switch (props.weight) {
      case "normal":
        return 400
      case "bold":
        return 700
      default:
        return 400
    }
  }};
  color: ${props => {
    switch (props.color) {
      case "footer":
        return "rgb(204, 204, 204)"
      case "dark1":
        return props.theme.colors.dark1
      case "dark2":
        return props.theme.colors.dark2
      case "dark3":
        return props.theme.colors.dark3
      case "light1":
        return props.theme.colors.light1
      case "light2":
        return props.theme.colors.light2
      default:
        return props.theme.colors.dark1
    }
  }};
  text-align: ${props => (props.textAlign ? props.textAlign : "left")};
`

// 共通h1タグ
export const H1 = styled.h1`
  font-size: 2.25rem;
  line-height: 2.5rem;
  color: ${props => {
    switch (props.color) {
      case "dark1":
        return props.theme.colors.dark1
      case "dark2":
        return props.theme.colors.dark2
      case "dark3":
        return props.theme.colors.dark3
      case "light1":
        return props.theme.colors.light1
      case "light2":
        return props.theme.colors.light2
      default:
        return props.theme.colors.dark1
    }
  }};
  font-weight: 400;
  text-align: ${props => (props.textAlign ? props.textAlign : "left")};
  margin: ${props => (props.margin ? props.margin : 0)};
`

// 固定ページ用h１タグボックス
export const FixedPageH1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;
  width: 100%;
  font-family: "Courgette", serif;
  letter-spacing: -0.125rem;
  text-align: center;

  & h1 {
    font-size: 1.5rem;
  }

  & span {
    display: block;
    font-size: 1rem;
  }

  &:before,
  :after {
    content: "";
    -webkit-box-flex: 1;
    flex: 1;
    height: 2px;
    background-color: #c8b0f9;
  }

  &:before {
    margin-right: 1rem;
  }

  &:after {
    margin-left: 1rem;
  }
`

// 共通h2タグ
export const H2 = styled.h2`
  font-size: 1.5rem;
  line-height: 1.875rem;
  color: ${props => {
    switch (props.color) {
      case "dark1":
        return props.theme.colors.dark1
      case "dark2":
        return props.theme.colors.dark2
      case "dark3":
        return props.theme.colors.dark3
      case "light1":
        return props.theme.colors.light1
      case "light2":
        return props.theme.colors.light2
      default:
        return props.theme.colors.dark1
    }
  }};
  font-weight: 400;
  text-align: ${props => (props.textAlign ? props.textAlign : "left")};
  margin: ${props => (props.margin ? props.margin : 0)};
`

// 固定ページ用h2タグ

export const FixedPageH2 = styled.h2`
  position: relative;
  padding-left: 1.5rem;
  font-family: "Courgette", serif;
  font-size: 2rem;
  line-height: 1.25;
  letter-spacing: -0.05rem;
  margin-bottom: 3rem;
  margin-top: 3rem;

  & :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 6px;
    height: 100%;
    background-color: #c8b0f9;
    border-radius: 60px;
  }
`

  // 固定ページ用H3タグ

export const FixedPageH3 = styled.h3`
  padding-left: 1.5rem;
  font-family: "Courgette", serif;
  font-size: 1.5rem;
  line-height: 1.25;
  letter-spacing: -0.05rem;
  margin-bottom: 3rem;
  margin-top: 3rem;
  border-bottom: 1px solid #c8b0f9;
`

  // 固定ページ用H4タグ

export const FixedPageH4 = styled.h3`
  padding-left: 1.5rem;
  font-family: "Courgette", serif;
  font-size: 1.5rem;
  line-height: 1.25;
  letter-spacing: -0.05rem;
  margin-bottom: 3rem;
  margin-top: 3rem;
  border-bottom: 1px solid #c8b0f9;
`
