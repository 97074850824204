import styled from "styled-components"

export const ContentWrapper = styled.section`
  grid-column: 3 / -3;
  margin-bottom: ${props => props.theme.spacings.xLarge};

  @media ${props => props.theme.breakpoints.mobile} {
    grid-column: 2 / -2;
  }
`
