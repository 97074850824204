import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import { HeaderWrapper } from "../elements"
import { PhoneNumber } from "../components"

export const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      marusuelogo: file(relativePath: { eq: "marusuelogo.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <HeaderWrapper>
      <Link to="/">
        <img alt="マルスエロゴ" src={data.marusuelogo.publicURL} />
      </Link>
      <div className="rightBox">
        <PhoneNumber text="弥富本店" />
        <Link to={`../contact/`}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <defs />
            <path d="M256 0C114.61 0 0 114.61 0 256s114.61 256 256 256 256-114.61 256-256S397.39 0 256 0zm149.57 268.88l-.12 55.37L339 257.81l66.57-66.57v77.64zm-97.13-11.07L277.8 288.4a30.58 30.58 0 01-21.8 9.05c-8.25 0-16-3.21-21.84-9.05L106.72 160.96a18 18 0 0117.73-14.88h263.1a18 18 0 0117.73 14.88l-96.84 96.84zm-202 66.4V191.27L173 257.8l-38.88 38.88-27.68 27.53zm1 29.7l80.84-80.83 30.6 30.6a52.19 52.19 0 0037.12 15.4 52.25 52.25 0 0037.12-15.4l30.6-30.6 80.84 80.84c-2.49 7-9.16 12-17.01 12h-263.1a18.05 18.05 0 01-17-12z" />
          </svg>
        </Link>
      </div>
    </HeaderWrapper>
  )
}
