import styled from "styled-components"

export const CardWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  box-shadow: ${props => props.theme.shadows.shadow3};

  & :focus,
  :hover {
    transform: scale(1.05, 1.05);
    transition: 0.5s all;
  }
`

export const CardImgWrapper = styled.div`
  flex-basis: 100%;
  height: 25vw;
  border-bottom: solid 2px #e9e9e9;
  display: flex;
  overflow: hidden;
  position: relative;

  .gatsby-image-wrapper {
    height: 29.8vw !important;
    width: 60% !important;
    flex-grow: 1;
    transform: skewX(-15deg);
    padding: 0.5rem 0;
    position: absolute;
    left: -10%;
  }

  img {
    transform: skewX(15deg) scale(1.6);
  }

  @media ${props => props.theme.breakpoints.mobile} {
    height: 200px;

    .gatsby-image-wrapper {
      height: 198px !important;
      width: 60% !important;
    }
  }
`

export const CardTitleWrapper = styled.div`
  flex-basis: 40%;
  flex-grow: none;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  position: relative;

  .title {
    font-weight: 400;
    font-size: 1rem;
    @media ${props => props.theme.breakpoints.mobile} {
      font-size: 0.75rem;
    }
  }

  .cover, .cover2 {
    font-weight: 600;
    font-size: 2rem;
    @media ${props => props.theme.breakpoints.mobile} {
      font-size: 1.5rem;
      margin-right: 1rem;
    }
  }

  .cover {
    margin-top: 2rem;
    margin-bottom: 1rem;
    @media ${props => props.theme.breakpoints.mobile} {
      font-size: 1.5rem;
      margin-right: 1rem;
    }
  }
`

export const CardTextWrapper = styled.div`
  padding: 2rem;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;

  & li:last-child {
    margin-bottom: 2rem;
  }

  & div {
    margin-top: auto;
    align-self: flex-end;
  }

  & li {
    list-style: none;
    margin-bottom: 0.5rem;
    color: ${props => props.theme.colors.main1};
  }

  & li:hover,
  li:focus {
    filter: brightness(110%);
  }

  /* 準備中用 */

  .yet {
    color: ${props => props.theme.colors.dark1};
  }

  @media ${props => props.theme.breakpoints.mobile} {
    align-items: center;

    & div {
      align-self: center;
    }
  }
`
