import styled from "styled-components"

export const PostWrapper = styled.main`
  grid-column: 3 / -3;
  background-color: white;
  padding: ${props =>
    `${props.theme.spacings.large} ${props.theme.spacings.xLarge}`};
  box-shadow: ${props => props.theme.shadows.shadows3};
  z-index: 10;
  margin-bottom: ${props => props.theme.spacings.medium};
  position: relative;

  * {
    line-height: 2;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${props => props.theme.colors.dark1};
  }

  h1:not(:first-child),
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2rem;
  }

  h1 {
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 400;
    text-align: center;
    margin-bottom: 6rem;
  }

  h2 {
    position: relative;
    padding: 20px 40px 18px;
    background-color: ${props => props.theme.colors.light1};
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.25;
    letter-spacing: -0.05rem;
    margin-bottom: 3rem;
  }

  & h2:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 6px;
    height: 100%;
    background-color: #c8b0f9;
    border-radius: 60px;
  }

  h3 {
    position: relative;
    padding: 10px 20px;
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 1.25;
    letter-spacing: -0.05rem;
    margin-bottom: 3rem;
  }

  & h3:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 6px;
    height: 100%;
    background-color: #c8b0f9;
    border-radius: 60px;
  }

  h4 {
    font-size: 1.25rem;
    line-height: 1.5em;
    font-weight: 400;
    position: relative;
  }

  h4::after {
    content: "";
    display: block;
    position: absolute;
    top: 0.5rem;
    left: -1.5em;
    width: 15px;
    height: 7.5px;
    border-left: 3px solid #c8b0f9;
    border-bottom: 3px solid #c8b0f9;
    transform: rotate(-45deg);
  }

  h5 {
    font-size: 1.125rem;
    line-height: 1.375em;
    font-weight: 700;
  }

  h6 {
    font-size: 1rem;
    line-height: 1.125em;
    font-weight: 700;
    text-transform: uppercase;
  }

  p {
    font-size: 1.125rem;
    font-weight: 400;
    color: ${props => props.theme.colors.dark2};
    margin: 3rem 0;
  }

  a {
    color: ${props => props.theme.colors.main1};
    text-decoration: underline;
  }

  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }

  del {
    text-decoration: line-through;
  }

  blockquote p {
    font-style: italic;
    /* font-size: 1.5rem; */
    line-height: 2.125rem;
    /* text-align: center; */
    max-width: 45rem;
    margin: 3rem auto;
  }

  ul,
  ol {
    color: ${props => props.theme.colors.dark2};
    margin: 1rem 0 1rem 0;
  }

  ul,
  ol,
  .epi {
    background-color: #f8f9ff;
    border: dashed 1px #4865b2;
    padding: 40px 15px 36px 40px;
  }

  .epi {
    padding: 2rem;
  }

  li {
    margin-bottom: 0.625rem;
  }

  code {
    font-family: ${props => props.theme.fonts.code};
    font-size: 1rem;
    line-height: 1.875rem;
    color: ${props => props.theme.colors.light1};
    background-color: ${props => props.theme.colors.dark3};
    padding: 0 0.3rem;
  }

  hr {
    border: 0;
    height: 1px;
    background: ${props => props.theme.colors.dark1};
    opacity: 0.1;
    margin-top: 2rem;
  }

  table {
    width: 100%;
    border-spacing: 0.25rem;
    border-collapse: collapse;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
  }

  th {
    font-weight: 700;
  }

  table,
  th,
  td {
    border: 1px solid ${props => props.theme.colors.dark3};
    border-collapse: collapse;
  }

  th,
  td {
    text-align: left;
    padding: 0.5rem;
  }

  /* 画像 */

  .mark300px {
    margin: 2rem auto;
    width: 30vw;
    height: auto;
  }

  /* 導入文 */

  .question {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  .question .question-text {
    grid-column: 2 / 5;
    padding: 0 1rem;
  }

  .question .gatsby-resp-image-wrapper {
    width: 100%;
  }

  .question .gatsby-resp-image-image {
    height: auto !important;
  }

  .answer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 2rem;
    @media ${props => props.theme.breakpoints.mobile} {
      margin-top: 0;
    }
  }

  .answer .answer-text {
    grid-column: 1 / 4;
    padding: 0 1rem 0 0;
  }

  .answer .gatsby-resp-image-wrapper {
    max-width: 150px !important;
    width: 100%;
    display: flex;
    margin: auto 0;
    border-radius: 50%;
  }

  .answer .gatsby-resp-image-background-image {
    border-radius: 50%;
  }

  .answer img {
    border-radius: 50%;
  }

  /* 画像の挿入デザイン */
  /* 左右二分割 */

  .half {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 2rem;
    @media ${props => props.theme.breakpoints.mobile} {
      margin-top: 0;
    }
  }

  .half .half-text {
    padding: 0 1rem;
  }

  .img-right .half-text {
    grid-column: 1 / 3;
  }

  .img-right .gatsby-resp-image-wrapper {
    grid-column: 3 / 5;
  }

  .img-left .half-text {
    grid-column: 3 / 5;
  }

  .img-left .gatsby-resp-image-wrapper {
    grid-column: 1 / 3;
  }

  .half .gatsby-resp-image-wrapper {
    width: 100%;
    display: flex;
    margin: auto 0;
  }

  @media ${props => props.theme.breakpoints.mobile} {
    grid-column: 2 / -2;
    padding: ${props =>
      `${props.theme.spacings.xSmall} ${props.theme.spacings.small}`};

    p {
      font-size: 1rem;
    }
  }

  /* float */

  .float .gatsby-resp-image-wrapper {
    width: 100% !important;
    margin-bottom: 1rem;
  }

  .right .gatsby-resp-image-wrapper {
    float: right;
    margin-left: 1rem !important;
    @media ${props => props.theme.breakpoints.mobile} {
      margin-left: 0;
    }
  }

  /* YouTube埋め込み用 */

  .youtube {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
  }

  .youtube iframe {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  /* 矢印 */
  /* <h2>右向き矢印</h2>
  <div class="Arrow-Right"></div>
  <h2>左向き矢印</h2>
  <div class="Arrow-Left"></div>
  <h2>上向き矢印</h2>
  <div class="Arrow-Top"></div>
  <h2>下向き矢印</h2>
  <div class="Arrow-Bottom"></div> */
  /* 
  .Arrow-Right {
  border-top: solid 4px #c8b0f9;
  border-right: solid 4px #c8b0f9;
  width: 24px;
  height: 24px;
  transform: rotate(45deg);
}
.Arrow-Left {
  border-bottom: solid 4px #c8b0f9;
  border-left: solid 4px #c8b0f9;
  width: 24px;
  height: 24px;
  transform: rotate(45deg);
}
.Arrow-Top {
  border-top: solid 4px #c8b0f9;
  border-left: solid 4px #c8b0f9;
  width: 24px;
  height: 24px;
  transform: rotate(45deg);
} */

  .Arrow-Bottom {
    border-bottom: solid 4px #c8b0f9;
    border-right: solid 4px #c8b0f9;
    margin: 2rem auto;
    width: 24px;
    height: 24px;
    transform: rotate(45deg);
  }

  /* サービスフロー */

  .service-flow {
    display: block;
    padding: 4rem 0;
    background-color: ${props => props.theme.colors.light1};
    @media ${props => props.theme.breakpoints.tablet} {
      display: block;
    }
  }

  .service-flow ul {
    padding: 0;
    margin: 0 auto;
    border: none;
    list-style: none;
    display: flex;
    flex-wrap: nowrap;
    background-color: ${props => props.theme.colors.light1};
    @media ${props => props.theme.breakpoints.tablet} {
      display: block;
    }
  }

  .service-flow li {
    background-color: white;
    width: 200px;
    height: 200px;
    margin: auto;
    border-radius: 50%;
    display: inline-flex;
    flex-direction: column;
    /* justify-content: center; */
    vertical-align: middle;
    @media ${props => props.theme.breakpoints.tablet} {
      flex-direction: row;
      width: 100%;
      height: auto;
      border-radius: 0;
    }
  }

  .service-flow svg {
    margin: 2rem auto 0 auto;
    width: 30px;
    height: 30px;
    @media ${props => props.theme.breakpoints.tablet} {
      margin: 1rem;
    }
  }

  .service-flow-title {
    margin: 0;
    text-align: center;
    @media ${props => props.theme.breakpoints.tablet} {
      text-align: left;
      margin-left: 2rem;
      font-size: 0.8rem;
    }
  }

  .service-flow-text {
    margin: 0 1rem;
    font-size: 0.8rem;
    text-align: center;
    @media ${props => props.theme.breakpoints.tablet} {
      text-align: left;
      font-size: 0.6rem;
    }
  }

  .service-flow .Arrow-Bottom {
    border-bottom: solid 2px ${props => props.theme.colors.dark1};
    border-right: solid 2px ${props => props.theme.colors.dark1};
    margin: 0.5rem 1rem 1rem 3rem;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
  }

  /* ブートストラップ拡張 */

  /* インスタグラム埋め込み */

  .instagram-media {
    border: 0;
    border-radius: 3px;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5), 0 1px 10px 0 rgba(0, 0, 0, 0.15);
    margin: 1px;
    max-width: 400px;
    width: 100%;
    min-width: 0 !important;
    padding: 0 2px 0 0;
    text-align: center;
  }

  .mdx-embed .iframe {
    overflow: scroll !important;
    width: 100%;
    height: 100%;
    border: none;
  }

  .mdx-embed {
    overflow: hidden;
    width: 100%;
    height: auto;
    border: none;
    @media ${props => props.theme.breakpoints.mobile} {
      height: 400px;
    }
  }
`
