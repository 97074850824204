import styled from "styled-components"

export const HeroWrapper = styled.div`
  grid-column: 2 / -2;
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-end;

  @media ${props => props.theme.breakpoints.mobile} {
    grid-column: 1 / -1;
  }
`

export const HeroInnerWrapper = styled.div`
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  clip: rect(0, auto, auto, 0);

  .hero_title {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 30%;
    width: 80%;
    color: white;
    letter-spacing: -0.125rem;
    font-family: "Courgette", serif;
    font-size: 2vw;
    line-height: 4vw;
    text-align: center;
    white-space: nowrap;

    @media ${props => props.theme.breakpoints.mobile} {
      font-size: 3.5vw;
      line-height: 8vw;
    }
  }

  @supports (-webkit-overflow-scrolling: touch) {
    clip: unset;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
`

export const HeroScrollWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 0.875rem;
  color: white;
  margin-right: 0.5rem;

  @media ${props => props.theme.breakpoints.mobile} {
    font-size: 2vw;
  }
`
export const HeroTextWrapper = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 1rem;
  width: 100%;
  height: 100%;
  color: white;
  font-family: "Courgette", serif;
  font-size: 6vw;
  letter-spacing: -0.125rem;
  text-align: center;
  z-index: 2;

  @media ${props => props.theme.breakpoints.tablet} {
    font-size: 4rem;
  }
`
