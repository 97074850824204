import React from "react"
import { PostWrapper } from "../elements"
// import {  } from "../components"
import 'bootstrap/dist/css/bootstrap.min.css'

export const Post = ({ children }) => {

  return (
    <PostWrapper>
      {/* <Instagram /> */}
      {children}
    </PostWrapper>
  )
}