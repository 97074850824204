import styled from "styled-components"

export const HeaderWrapper = styled.header`
  background-color: ${props => props.theme.colors.light2};
  grid-column: 2 / -2;
  display: flex;
  align-items: center;
  padding-top: 1rem;
  z-index: 100;

  @media ${props => props.theme.breakpoints.mobile} {
    grid-column: 1 / -1;
    /* flex-direction: column; */
    height: auto;
    padding: 0.5rem 0 0.5rem 0.5rem;
  }

  & img {
    height: 3rem;
    margin-left: 2rem;

    @media ${props => props.theme.breakpoints.mobile} {
      height: 2rem;
      margin: 0.5rem 0 0 0;
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }

  & a:hover,
  a:focus {
    @media ${props => props.theme.breakpoints.mobile} {
      filter: brightness(0.5);
    }
  }

  button {
    border: 2px solid #c8b0f9 !important;
    color: #c8b0f9 !important;
  }

  button:hover {
    color: white !important;
    background: #c8b0f9 !important;
  }
  button:active {
    letter-spacing: 2px;
  }

  & svg {
    padding-left: 0.5rem;
    padding-top: 4px;
    fill: ${props => props.theme.colors.dark3};
    width: 60px;
    @media ${props => props.theme.breakpoints.mobile} {
      display: none;
    }
  }

  & svg:hover {
    fill: ${props => props.theme.colors.dark1};
    transition: 1s all;
  }

  & .rightBox {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 1rem;
    @media ${props => props.theme.breakpoints.mobile} {
      margin: auto;
    }
  }
`
