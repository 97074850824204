import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import {
  FooterWrapper,
  FooterSocialWrapper,
  FooterSocialIcons,
  FooterImgWrapper,
  P,
} from "../elements"

export const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      pattern: file(relativePath: { eq: "pattern.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <FooterWrapper>
      <FooterSocialWrapper>
        <FooterSocialIcons>
          <a
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 22 22"
            >
              <path
                fill="hsl(0,0%,80%)"
                d="M2 0h18c.542 0 1 .208 1.375.625C21.792 1 22 1.458 22 2v18c0 .542-.208 1.02-.625 1.438A1.87 1.87 0 0120 22H2a2.09 2.09 0 01-1.438-.563A2.086 2.086 0 010 20V2C0 1.458.188 1 .563.625.978.208 1.458 0 2 0zm0 2v18h9.688v-6.75H9.062v-3h2.626V8c0-1.25.354-2.23 1.062-2.938C13.458 4.354 14.396 4 15.563 4c1.083 0 1.854.042 2.312.125v2.75H16.25c-.542 0-.917.125-1.125.375-.208.208-.313.563-.313 1.063v1.937h2.938l-.375 3h-2.563V20H20V2H2z"
              />
            </svg>
          </a>
          <a
            href="https://instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 22 22"
            >
              <defs />
              <path
                fill="hsl(0,0%,80%)"
                d="M3 0h16c.833 0 1.542.292 2.125.875S22 2.167 22 3v16c0 .833-.292 1.542-.875 2.125A2.893 2.893 0 0119 22H3a2.893 2.893 0 01-2.125-.875A2.893 2.893 0 010 19V3C0 2.167.292 1.458.875.875A2.893 2.893 0 013 0zm13 3v3h3V3h-3zm-2.188 5.188C13.063 7.396 12.126 7 11 7c-1.125 0-2.083.396-2.875 1.188C7.375 8.938 7 9.874 7 11c0 1.125.375 2.083 1.125 2.875C8.917 14.625 9.875 15 11 15s2.063-.375 2.813-1.125C14.604 13.083 15 12.125 15 11s-.396-2.063-1.188-2.813zM2 9v10c0 .667.333 1 1 1h16c.667 0 1-.333 1-1V9h-3.375c.25.667.375 1.333.375 2 0 1.667-.583 3.083-1.75 4.25C14.083 16.417 12.667 17 11 17c-1.667 0-3.083-.583-4.25-1.75C5.583 14.083 5 12.667 5 11c0-.667.125-1.333.375-2H2z"
              />
            </svg>
          </a>
          <a
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 22 22"
            >
              <defs />
              <path
                fill="hsl(0,0%,80%)"
                d="M22 3.576a9.553 9.553 0 01-2.234 2.44v.595c0 1.586-.287 3.153-.86 4.7a14.925 14.925 0 01-2.463 4.283c-1.032 1.31-2.387 2.36-4.068 3.154-1.642.793-3.457 1.19-5.443 1.19-2.52 0-4.831-.695-6.932-2.083.344.04.707.06 1.089.06 2.1 0 3.972-.675 5.614-2.023-.993 0-1.871-.298-2.635-.893a5.03 5.03 0 01-1.604-2.38c.267.08.553.12.859.12.42 0 .821-.06 1.203-.179a4.625 4.625 0 01-2.635-1.606A4.678 4.678 0 01.859 7.979v-.06c.65.357 1.337.556 2.063.595C1.585 7.563.917 6.254.917 4.588c0-.833.21-1.626.63-2.38 2.406 3.094 5.5 4.74 9.281 4.938a5.104 5.104 0 01-.114-1.07c0-1.31.439-2.42 1.317-3.333.879-.912 1.948-1.368 3.209-1.368 1.298 0 2.387.496 3.265 1.487a8.217 8.217 0 002.865-1.13c-.344 1.11-.993 1.963-1.948 2.558A10.004 10.004 0 0022 3.576z"
              />
            </svg>
          </a>
        </FooterSocialIcons>
        <P color="footer" size="xSmall">
          © 2020 Marusue. All right reserved
        </P>
      </FooterSocialWrapper>
      <FooterImgWrapper>
        <Img
          fluid={data.pattern.childImageSharp.fluid}
          alt="変わり塗り"
          overlayColor="#04040454"
          style={{ height: "100%" }}
          loading="eager"
          durationFadeIn={100}
        />
        <div className="overlay"></div>
      </FooterImgWrapper>
    </FooterWrapper>
  )
}
