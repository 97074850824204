import React from "react"
import Img from "gatsby-image"
import {
  CardWrapper,
  CardImgWrapper,
  CardTitleWrapper,
  CardTextWrapper,
  P,
} from "../elements"

export const ContentCard = ({ title, cover, cover2, text, children, fixed, fluid }) => {
  return (
    <CardWrapper>
      <CardImgWrapper>
        <Img 
          fixed={fixed} 
          fluid={fluid} 
          loading="eager" 
          durationFadeIn={100} />
        <CardTitleWrapper>
          <h3 className="title">{title}</h3>
          <P className="cover" font="cover">
            {cover}
          </P>
          <P className="cover2" font="cover">
            {cover2}
          </P>
          <P className="text">{text}</P>
        </CardTitleWrapper>
      </CardImgWrapper>
      <CardTextWrapper>{children}</CardTextWrapper>
    </CardWrapper>
  )
}
