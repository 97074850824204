import styled from "styled-components"

export const ProfileWrapper = styled.div`

.question {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: ${props => props.theme.spacings.large};
    @media ${props => props.theme.breakpoints.mobile} {
    display: block;
    }
  }

  .question .question-text {
    grid-column: 2 / 5;
    padding: 0 1rem;
  }

  .question .gatsby-image-wrapper {
    grid-column: 1 / 2;
    @media ${props => props.theme.breakpoints.mobile} {
    margin-bottom: 2rem;
    }
  }

.question2 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    @media ${props => props.theme.breakpoints.mobile} {
    display: block;
    }
  }

  .question2  .question-text {
    grid-column: 1 / 4;
    grid-row: 1 / 2;
    padding: 0 1rem;
  }

  .question2 img {
    height: auto !important;
    border-radius: 50%;
  }

  .question2 .gatsby-image-wrapper {
    display: flex;
    margin: auto 0;
    grid-column: 4 / 5;
    width: auto;
  }

  & table {
    border-collapse: collapse;
    width: 100%;
    font-size: 0.75rem;
    line-height: 1.75rem;
  }

  & td {
    padding: 0 0.5rem;
  }

  & .history td {
    border-bottom: 1px solid #c8b0f9
  }
`