import React from 'react'
import Helmet from 'react-helmet'

export const Iframely = () => {
  return (
    <Helmet>
      <script async type="text/javascript" src="https://cdn.iframe.ly/embed.js" charset="utf-8" />
    </Helmet>
  )
}
