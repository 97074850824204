import React from "react"
import { ContactWrapper } from "../elements"

export const PhoneNumber = ({ text, width }) => {
  return (
    <ContactWrapper>
      <a href="tel:0567670707">
        <button style={{ width: width }}>{text}(0567-67-0707)</button>
      </a>
    </ContactWrapper>
  )
}
